import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { heroFilterStatic } from '../../static-data';

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const HeroFilterSlide = props => {
  const {
    title,
    animateletter,
    color,
    image,
    idx,
  } = props;

  const [ currentText, setCurrentText ] = useState('');
  const [ isTyping, setIsTyping ] = useState(false);

  useEffect(() => {
    let timeout;
    const delayTime = Number(1800 / animateletter.length);
    if (currentText.length < animateletter.length) {
      timeout = setTimeout(() => {
        setCurrentText(animateletter.slice(0, currentText.length + 1));
      }, currentText === '' ? 400 : delayTime);
    }
    return () => clearTimeout(timeout);
  }, [ currentText, animateletter ]);

  useEffect(() => {
    setIsTyping(false);

    const timer = setTimeout(() => {
      setIsTyping(true);
    }, 2400);

    setCurrentText('');
    return () => clearTimeout(timer);
  }, [ animateletter ]);
  return (
    <div className={`hero-block__slide hero-block__slide--${idx}`}>
      <h2 className='hero-block__title'>
        {title}
        <br />
        {' '}
        <strong
          className='hero-block__letters'
          style={{ color: `${isTyping ? '#fff' : color}`, backgroundColor: `${isTyping ? color : 'transparent'}` }}
        >
          {currentText}

        </strong>
        {image
          && (
            <img
              src={image.src}
              alt={image.alt}
            />
          )}
      </h2>
    </div>
  );
};

const HeroFilter = () => {
  const [ animateText, setAnimateText ] = useState(heroFilterStatic[0].animateLetter);
  const [ currentSlide, setCurrentSlide ] = useState(0);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 0,
    fade: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: false,
    pauseOnFocus: false,
    beforeChange: (currentIndex, nextIndex) => { setAnimateText(heroFilterStatic[nextIndex].animateLetter); setCurrentSlide(nextIndex); },
  };

  return (
    <section className='hero hero--filter'>
      <div className='container'>
        <div className='hero-block__inner'>
          <Slider className={`hero-block__carousel hero-block__slide--${currentSlide}`} {...settings}>
            {
              heroFilterStatic?.map((slideItem, i) => <HeroFilterSlide title={slideItem.title} color={slideItem.color} animateletter={animateText} image={slideItem.image} idx={i} key={slideItem.animateLetter} />)
            }
          </Slider>
        </div>
        <div className='hero-block__content'>
          <div className='hero-block__description'>
            <p>
              Unlike generic air purifier filters that try to do a little bit of everything (and do nothing well), Mila
              offers 7 unique filters to address the specific needs of air breathers.
            </p>
          </div>
          <h3 className='hero-block__subtitle'>Which one are you?</h3>
        </div>

      </div>
    </section>
  );
};

export default HeroFilter;
